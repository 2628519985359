var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card',{staticClass:"mt-3",attrs:{"loading":_vm.loading}},[_c('Page-Header',{attrs:{"title":_vm.$t('itemTemplate.itemTemplates'),"icon":"mdi-notebook-outline"},scopedSlots:_vm._u([{key:"buttons",fn:function(){return [_c('v-btn',{attrs:{"loading":_vm.loading,"color":"primary darken-1"},nativeOn:{"click":function($event){_vm.dialog = true}}},[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v(" "+_vm._s(_vm.$t("add"))+" ")],1)]},proxy:true}])}),_c('v-data-table',{staticClass:"pa-5",attrs:{"headers":_vm.headers,"items":_vm.plans,"search":_vm.search,"loading":_vm.loading,"loading-text":_vm.$t('loading')},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-text-field',{attrs:{"dense":"","outlined":"","append-icon":"mdi-magnify","label":_vm.$t('search'),"single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('v-dialog',{attrs:{"persistent":"","max-width":"800px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-card',[_c('v-toolbar',{attrs:{"color":"primary darken-1","dark":""}},[_c('v-card-title',[_c('span',{},[_vm._v(_vm._s((_vm.editedIndex === -1 ? _vm.$t("add") : _vm.$t("edit")) + " " + _vm.$t("itemTemplate.itemTemplate")))])])],1),_c('v-card-text',[_c('v-container',[_c('v-row',{staticClass:"mb-2"},[_c('v-text-field',{attrs:{"outlined":"","hide-details":"","dense":"","rules":_vm.rules,"label":_vm.$t(
                                                    'itemTemplate.templateName'
                                                )},model:{value:(_vm.editedItem.title),callback:function ($$v) {_vm.$set(_vm.editedItem, "title", $$v)},expression:"editedItem.title"}})],1),_c('v-row',{staticClass:"my-2"},[_c('v-btn',{attrs:{"color":"primary darken-1 white--text","outlined":"","block":""},on:{"click":_vm.addItem}},[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v(" "+_vm._s(_vm.$t("add"))+" ")],1)],1),_c('v-row',[_c('v-container',{attrs:{"fluid":""}},[_c('v-card',{attrs:{"max-height":"330","elevation":"0","flat":"","outlined":_vm.editedItem.planItems
                                                        .length > 0}},[_c('v-list',_vm._l((_vm.editedItem.planItems),function(item,i){return _c('v-list-item',{key:i},[_c('v-list-item-content',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-autocomplete',{attrs:{"label":_vm.$t(
                                                                                'itemTemplate.fee'
                                                                            ),"hide-details":"","outlined":"","dense":"","hide-selected":"","items":_vm.items,"item-text":"itemName","item-value":"itemGuid","rules":_vm.rules},on:{"change":function($event){return _vm.itemChanged(
                                                                                item.itemGuid,
                                                                                i
                                                                            )},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.addItem($event)}},model:{value:(
                                                                            item.itemGuid
                                                                        ),callback:function ($$v) {_vm.$set(item, "itemGuid", $$v)},expression:"\n                                                                            item.itemGuid\n                                                                        "}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"type":"number","hide-details":"","label":_vm.$t(
                                                                                'item.price'
                                                                            ),"rules":_vm.rules,"outlined":"","dense":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.addItem($event)}},model:{value:(
                                                                            item.price
                                                                        ),callback:function ($$v) {_vm.$set(item, "price", $$v)},expression:"\n                                                                            item.price\n                                                                        "}})],1)],1)],1),_c('v-list-item-action',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                                                    var on = ref.on;
                                                                    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"red lighten-1"},on:{"click":function($event){return _vm.removeItem(
                                                                                i
                                                                            )}}},'v-btn',
                                                                            attrs
                                                                        ,false),
                                                                            on
                                                                        ),[_c('v-icon',[_vm._v(" mdi-delete-circle-outline ")])],1)]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t( "remove" ))+" ")])])],1)],1)}),1)],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"type":"number","min":"0","hide-details":"","label":_vm.$t(
                                                        'itemTemplate.discount'
                                                    ),"outlined":"","dense":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.addItem($event)}},model:{value:(
                                                    _vm.editedItem.discount
                                                ),callback:function ($$v) {_vm.$set(_vm.editedItem, "discount", $$v)},expression:"\n                                                    editedItem.discount\n                                                "}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"type":"number","min":"0","hide-details":"","label":_vm.$t('itemTemplate.extra'),"outlined":"","dense":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.addItem($event)}},model:{value:(_vm.editedItem.extra),callback:function ($$v) {_vm.$set(_vm.editedItem, "extra", $$v)},expression:"editedItem.extra"}})],1),_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-text-field',{attrs:{"outlined":"","dense":"","hide-details":"","label":_vm.$t('itemTemplate.notes')},model:{value:(_vm.editedItem.notes),callback:function ($$v) {_vm.$set(_vm.editedItem, "notes", $$v)},expression:"editedItem.notes"}})],1)],1)],1)],1),_c('v-card-actions',[(_vm.isInRole(89))?_c('v-btn',{attrs:{"color":"primary darken-1 white--text","disabled":!_vm.valid,"min-width":100},on:{"click":_vm.save}},[_c('v-icon',[_vm._v("mdi-content-save-outline")]),_vm._v(" "+_vm._s(_vm.$t("save"))+" ")],1):_vm._e(),_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"red"},on:{"click":_vm.close}},[_vm._v(" "+_vm._s(_vm.$t("cancel"))+" ")])],1)],1)],1)],1),_c('confirm-dialog',{attrs:{"openDialog":_vm.dialogDelete,"onClicked":_vm.deleteItemConfirm,"onClose":_vm.closeDelete,"toolBarColor":"red darken-2"}})]},proxy:true},{key:"item.actions",fn:function(ref){
                                                var item = ref.item;
return [_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                                var on = ref.on;
                                                var attrs = ref.attrs;
return [(_vm.isInRole(89))?_c('v-icon',_vm._g(_vm._b({on:{"click":function($event){return _vm.editItem(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("edit"))+" ")])])],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                                var on = ref.on;
                                                var attrs = ref.attrs;
return [(_vm.isInRole(90))?_c('v-icon',_vm._g(_vm._b({attrs:{"color":"red"},on:{"click":function($event){return _vm.deleteItem(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-delete-outline ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("delete")))])])],1)],1)]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }