<template>
    <v-container fluid>
        <v-card :loading="loading" class="mt-3">
            <Page-Header
                :title="$t('itemTemplate.itemTemplates')"
                icon="mdi-notebook-outline"
            >
                <template v-slot:buttons>
                    <v-btn
                        :loading="loading"
                        color="primary darken-1"
                        @click.native="dialog = true"
                    >
                        <v-icon>mdi-plus</v-icon>
                        {{ $t("add") }}
                    </v-btn>
                </template>
            </Page-Header>
            <v-data-table
                :headers="headers"
                :items="plans"
                class="pa-5"
                :search="search"
                :loading="loading"
                :loading-text="$t('loading')"
            >
                <template v-slot:top>
                    <!--Search bar-->
                    <v-text-field
                        dense
                        outlined
                        v-model="search"
                        append-icon="mdi-magnify"
                        :label="$t('search')"
                        single-line
                        hide-details
                    ></v-text-field>
                    <v-spacer></v-spacer>
                    <!--Save popup-->
                    <v-dialog v-model="dialog" persistent max-width="800px">
                        <v-form ref="form" v-model="valid" lazy-validation>
                            <v-card>
                                <v-toolbar color="primary darken-1" dark>
                                    <v-card-title>
                                        <span class="">{{
                                            (editedIndex === -1
                                                ? $t("add")
                                                : $t("edit")) +
                                                " " +
                                                $t("itemTemplate.itemTemplate")
                                        }}</span>
                                    </v-card-title>
                                </v-toolbar>
                                <v-card-text>
                                    <v-container>
                                        <v-row class="mb-2">
                                            <v-text-field
                                                outlined
                                                hide-details
                                                dense
                                                v-model="editedItem.title"
                                                :rules="rules"
                                                :label="
                                                    $t(
                                                        'itemTemplate.templateName'
                                                    )
                                                "
                                            ></v-text-field>
                                        </v-row>
                                        <v-row class="my-2">
                                            <v-btn
                                                color="primary darken-1 white--text"
                                                outlined
                                                block
                                                @click="addItem"
                                            >
                                                <v-icon>mdi-plus</v-icon>
                                                {{ $t("add") }}
                                            </v-btn>
                                        </v-row>
                                        <v-row>
                                            <v-container fluid>
                                                <v-card
                                                    max-height="330"
                                                    elevation="0"
                                                    flat
                                                    :outlined="
                                                        editedItem.planItems
                                                            .length > 0
                                                    "
                                                >
                                                    <v-list>
                                                        <v-list-item
                                                            v-for="(item,
                                                            i) in editedItem.planItems"
                                                            :key="i"
                                                        >
                                                            <v-list-item-content>
                                                                <v-row>
                                                                    <v-col
                                                                        cols="12"
                                                                        md="6"
                                                                    >
                                                                        <v-autocomplete
                                                                            :label="
                                                                                $t(
                                                                                    'itemTemplate.fee'
                                                                                )
                                                                            "
                                                                            hide-details
                                                                            v-model="
                                                                                item.itemGuid
                                                                            "
                                                                            outlined
                                                                            dense
                                                                            hide-selected
                                                                            :items="
                                                                                items
                                                                            "
                                                                            item-text="itemName"
                                                                            item-value="itemGuid"
                                                                            v-on:change="
                                                                                itemChanged(
                                                                                    item.itemGuid,
                                                                                    i
                                                                                )
                                                                            "
                                                                            :rules="
                                                                                rules
                                                                            "
                                                                            v-on:keyup.enter="
                                                                                addItem
                                                                            "
                                                                        >
                                                                        </v-autocomplete>
                                                                    </v-col>
                                                                    <v-col
                                                                        cols="12"
                                                                        md="6"
                                                                    >
                                                                        <v-text-field
                                                                            type="number"
                                                                            hide-details
                                                                            v-on:keyup.enter="
                                                                                addItem
                                                                            "
                                                                            :label="
                                                                                $t(
                                                                                    'item.price'
                                                                                )
                                                                            "
                                                                            :rules="
                                                                                rules
                                                                            "
                                                                            outlined
                                                                            dense
                                                                            v-model="
                                                                                item.price
                                                                            "
                                                                        >
                                                                        </v-text-field>
                                                                    </v-col>
                                                                </v-row>
                                                            </v-list-item-content>
                                                            <v-list-item-action>
                                                                <v-tooltip top>
                                                                    <template
                                                                        v-slot:activator="{
                                                                            on,
                                                                            attrs
                                                                        }"
                                                                    >
                                                                        <v-btn
                                                                            v-bind="
                                                                                attrs
                                                                            "
                                                                            v-on="
                                                                                on
                                                                            "
                                                                            @click="
                                                                                removeItem(
                                                                                    i
                                                                                )
                                                                            "
                                                                            icon
                                                                            color="red lighten-1"
                                                                        >
                                                                            <v-icon>
                                                                                mdi-delete-circle-outline
                                                                            </v-icon>
                                                                        </v-btn>
                                                                    </template>
                                                                    <span>
                                                                        {{
                                                                            $t(
                                                                                "remove"
                                                                            )
                                                                        }}
                                                                    </span>
                                                                </v-tooltip>
                                                            </v-list-item-action>
                                                        </v-list-item>
                                                    </v-list>
                                                </v-card></v-container
                                            >
                                        </v-row>
                                        <v-row>
                                            <v-col cols="12" md="6"
                                                ><v-text-field
                                                    type="number"
                                                    min="0"
                                                    hide-details
                                                    v-on:keyup.enter="addItem"
                                                    :label="
                                                        $t(
                                                            'itemTemplate.discount'
                                                        )
                                                    "
                                                    outlined
                                                    dense
                                                    v-model="
                                                        editedItem.discount
                                                    "
                                                >
                                                </v-text-field
                                            ></v-col>
                                            <v-col cols="12" md="6"
                                                ><v-text-field
                                                    type="number"
                                                    min="0"
                                                    hide-details
                                                    v-on:keyup.enter="addItem"
                                                    :label="
                                                        $t('itemTemplate.extra')
                                                    "
                                                    outlined
                                                    dense
                                                    v-model="editedItem.extra"
                                                >
                                                </v-text-field
                                            ></v-col>
                                            <v-col cols="12" md="12">
                                                <v-text-field
                                                    outlined
                                                    dense
                                                    hide-details
                                                    v-model="editedItem.notes"
                                                    :label="
                                                        $t('itemTemplate.notes')
                                                    "
                                                ></v-text-field>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-card-text>
                                <v-card-actions>
                                    <v-btn
                                        v-if="isInRole(89)"
                                        color="primary darken-1 white--text"
                                        @click="save"
                                        :disabled="!valid"
                                        :min-width="100"
                                    >
                                        <v-icon
                                            >mdi-content-save-outline</v-icon
                                        >
                                        {{ $t("save") }}
                                    </v-btn>
                                    <v-spacer></v-spacer>
                                    <v-btn text color="red" @click="close">
                                        {{ $t("cancel") }}
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-form>
                    </v-dialog>
                    <!--Delete popup-->
                    <confirm-dialog
                        :openDialog="dialogDelete"
                        :onClicked="deleteItemConfirm"
                        :onClose="closeDelete"
                        toolBarColor="red darken-2"
                    ></confirm-dialog>
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                    <v-row no-gutters>
                        <v-col cols="6"
                            ><v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon
                                        v-if="isInRole(89)"
                                        @click="editItem(item)"
                                        v-bind="attrs"
                                        v-on="on"
                                    >
                                        mdi-pencil
                                    </v-icon>
                                </template>
                                <span> {{ $t("edit") }} </span>
                            </v-tooltip></v-col
                        >
                        <v-col cols="6"
                            ><v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon
                                        v-if="isInRole(90)"
                                        color="red"
                                        @click="deleteItem(item)"
                                        v-bind="attrs"
                                        v-on="on"
                                    >
                                        mdi-delete-outline
                                    </v-icon>
                                </template>
                                <span> {{ $t("delete") }}</span>
                            </v-tooltip></v-col
                        >
                    </v-row>
                </template>
            </v-data-table>
        </v-card>
    </v-container>
</template>
<script>
import axios from "axios";
import ConfirmDialog from "../../components/ConfirmDialog.vue";
import PageHeader from "../../components/PageHeader.vue";

export default {
    components: { ConfirmDialog, PageHeader },
    data() {
        return {
            plans: [],
            uniqueIitemTemplates: [],
            planItems: [],
            items: [],
            payTypes: [
                { key: this.$t("payTypes.cash"), value: 1 },
                { key: this.$t("payTypes.delayed"), value: 2 }
            ],
            fees: [],
            activePicker: null,
            date: null,
            menu: false,
            valid: true,
            loading: true,
            dialog:
                this.$route.query.showDialog != undefined
                    ? this.$route.query.showDialog
                    : false,
            dialogDelete: false,
            editedIndex: -1,
            search: "",
            headers: [
                {
                    text: this.$t("itemTemplate.templateName"),
                    value: "title"
                },
                {
                    text: this.$t("itemTemplate.notes"),
                    value: "notes"
                },
                { text: "", value: "actions" }
            ],
            editedItem: {
                planGuid: null,
                title: null,
                notes: null,
                discount: 0,
                extra: 0,
                planItems: []
            },
            defaultItem: {
                planGuid: null,
                title: null,
                notes: null,
                discount: 0,
                extra: 0,
                planItems: []
            },
            rules: [value => !!value || value == 0 || this.$t("required")]
        };
    },
    created() {
        if (this.isInRole(87)) {
            this.loading = true;
            this.refreshData();
        } else this.redirectUnauthorizedUsers();
    },
    watch: {
        dialog(val) {
            val || this.close();
        },
        dialogDelete(val) {
            val || this.closeDelete();
        },
        menu(val) {
            val && setTimeout(() => (this.activePicker = "YEAR"));
        }
    },
    methods: {
        saveDate(date) {
            this.$refs.menu.save(date);
        },
        editItem(item) {
            this.editedIndex = this.plans.indexOf(item);
            this.editedItem = JSON.parse(JSON.stringify(item));
            this.dialog = true;
        },
        deleteItem(item) {
            this.editedIndex = this.plans.indexOf(item);
            this.editedItem = Object.assign({}, item);
            this.dialogDelete = true;
        },
        deleteItemConfirm() {
            var deleteItem = this.plans[this.editedIndex];
            axios
                .delete("Plans/Delete?id=" + deleteItem.planGuid)
                .then(response => {
                    if (response.data.status == "Successful") {
                        this.$toast.success(
                            this.$t("operationAccomplishedSuccessfully")
                        );
                    } else {
                        this.$toast.error(
                            this.$t("error." + response.data.message)
                        );
                    }
                    this.refreshData();
                })
                .catch(e => {
                    this.$toast.error(
                        this.$t("AnErrorOccurredDuringTheProcess")
                    );
                    this.refreshData();
                    console.log(e);
                });

            this.closeDelete();
        },
        close() {
            this.dialog = false;

            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
            });

            this.$refs.form.reset();
            this.$refs.form.resetValidation();
        },
        closeDelete() {
            this.dialogDelete = false;
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
            });
        },
        refreshData() {
            axios
                .get("Items/Get")
                .then(response => {
                    this.items = response.data.data.filter(function(obj) {
                        return (
                            obj.parentGuid !=
                            "00000000-0000-0000-0000-000000000000"
                        );
                    });
                })
                .catch(e => {
                    this.$toast.error(
                        this.$t("AnErrorOccurredDuringTheProcess")
                    );
                    console.log(e);
                })
                .finally(() => {
                    this.loading = false;
                });

            axios
                .get("Plans/Get")
                .then(response => {
                    this.plans = response.data.data;
                })
                .catch(e => {
                    this.$toast.error(
                        this.$t("AnErrorOccurredDuringTheProcess")
                    );
                    console.log(e);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        save() {
            if (this.$refs.form.validate()) {
                if (this.editedIndex == -1) {
                    this.editedItem.planGuid =
                        "00000000-0000-0000-0000-000000000000";

                    this.editedItem.price = 0;
                    this.editedItem.quantity = 0;
                }

                if (
                    this.editedItem.extra == undefined ||
                    this.editedItem.extra == null
                ) {
                    this.editedItem.extra = 0;
                }

                if (
                    this.editedItem.discount == undefined ||
                    this.editedItem.discount == null
                ) {
                    this.editedItem.discount = 0;
                }

                axios
                    .post("Plans/Save", this.editedItem)
                    .then(response => {
                        if (response.data.status == "Successful") {
                            this.$toast.success(
                                this.$t("operationAccomplishedSuccessfully")
                            );
                            this.close();
                        } else {
                            this.$toast.error(
                                this.$t("error." + response.data.message)
                            );
                        }
                        this.refreshData();
                    })
                    .catch(e => {
                        this.$toast.error(
                            this.$t("AnErrorOccurredDuringTheProcess")
                        );
                        this.refreshData();
                        console.log(e);
                    });
            }
        },
        addItem() {
            try {
                this.editedItem.planItems.push({ price: 0 });
            } catch (error) {
                console.log(error);
            }
        },
        removeItem(index) {
            this.editedItem.planItems.splice(index, 1);
        },
        itemChanged: function(itemGuid, index) {
            var selectedItem = this.items.filter(function(obj) {
                return obj.itemGuid == itemGuid;
            })[0];

            var row = {};

            this.editedItem.planItems[index] = row;

            if (selectedItem != undefined || selectedItem != null) {
                row.itemGuid = selectedItem.itemGuid;
                row.price = selectedItem.price1;
            }

            this.editedItem.planItems.splice(index, 1, row);
        }
    }
};
</script>
